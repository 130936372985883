import { addNavMenuSection } from '@vendure/admin-ui/core';

export default [
  addNavMenuSection({
    id: 'teams',
    label: 'Teams',
    items: [
      {
        id: 'teams',
        label: 'Teams',
        routerLink: ['/extensions/teams'],
        icon: 'user',
      },
    ],
  }),
];
