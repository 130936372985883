import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteExtensionModule } from './extensions/575f29527a2cadb79e35c1ad9500e223847d0bdce79eadff0468fd6054a1f56f/quote-ui-extension.module';
import { PagesSharedModule } from './extensions/pages/pages-ui-shared.module';
import { ContactExtensionModule } from './extensions/9ace3810332d3a0cdc5897cb4545edf6fd0306524fc333a1711caa52e5293a0d/contact-ui-extension.module';
import { ReviewsUiExtensionModule } from './extensions/e8454f33badbc89d3d274ebfd0ddeb010353c0b11e0386bfa6b454dde1ffd98c/reviews-ui-extension.module';
import { NewsletterSharedModule } from './extensions/5a7ffef653bf179407b34a4432471627cb96e2f61b6d950c4fca6a75900f1e94/newsletter-ui-extension.module';
import { OnBlockContactExtensionModule } from './extensions/b8f4e5fbadc0365efc5f48b1002352006f189f583417ade9dcfc8296f0b165c2/on-block-contact-ui-extension.module';
import { IdeaContactExtensionModule } from './extensions/ba2079f4bd2fb1d099f9902e1844666e4d0aadcb4451194c33855104ecd882ad/contact-ui-extension.module';

import SharedProviders_2_0 from './extensions/teams-ui/providers';
import SharedProviders_6_0 from './extensions/referral-plugin/providers';


@NgModule({
    imports: [CommonModule, QuoteExtensionModule, PagesSharedModule, ContactExtensionModule, ReviewsUiExtensionModule, NewsletterSharedModule, OnBlockContactExtensionModule, IdeaContactExtensionModule],
    providers: [...SharedProviders_2_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
