export const extensionRoutes = [  {
    path: 'extensions/quote',
    loadChildren: () => import('./extensions/575f29527a2cadb79e35c1ad9500e223847d0bdce79eadff0468fd6054a1f56f/quote-ui-lazy.module').then(m => m.QuoteUIModule),
  },
  {
    path: 'extensions/page',
    loadChildren: () => import('./extensions/pages/pages-ui-lazy.module').then(m => m.PagesLazyModule),
  },
  {
    path: 'extensions/teams',
    loadChildren: () => import('./extensions/teams-ui/routes'),
  },
  {
    path: 'extensions/contact',
    loadChildren: () => import('./extensions/9ace3810332d3a0cdc5897cb4545edf6fd0306524fc333a1711caa52e5293a0d/contact-ui-lazy.module').then(m => m.ContactUIModule),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/e8454f33badbc89d3d274ebfd0ddeb010353c0b11e0386bfa6b454dde1ffd98c/reviews-ui-lazy.module').then(m => m.ReviewsUiLazyModule),
  },
  {
    path: 'extensions/newsletter',
    loadChildren: () => import('./extensions/5a7ffef653bf179407b34a4432471627cb96e2f61b6d950c4fca6a75900f1e94/newsletter-ui-lazy.module').then(m => m.NewsletterLazyModule),
  },
  {
    path: 'extensions/referral',
    loadChildren: () => import('./extensions/referral-plugin/routes'),
  },
  {
    path: 'extensions/onblockitcontact',
    loadChildren: () => import('./extensions/b8f4e5fbadc0365efc5f48b1002352006f189f583417ade9dcfc8296f0b165c2/on-block-contact-ui-lazy.module').then(m => m.OnBlockContactUIModule),
  },
  {
    path: 'extensions/ideacontact',
    loadChildren: () => import('./extensions/ba2079f4bd2fb1d099f9902e1844666e4d0aadcb4451194c33855104ecd882ad/contact-ui-lazy.module').then(m => m.IdeaContactUIModule),
  }];
